// extracted by mini-css-extract-plugin
export var container = "ContactSupport-module--container--gTmPs";
export var contact_container = "ContactSupport-module--contact_container--JM9J+";
export var p = "ContactSupport-module--p--7Yxjl";
export var h2 = "ContactSupport-module--h2--w0MI3";
export var h3 = "ContactSupport-module--h3--rCqNc";
export var h4 = "ContactSupport-module--h4--Su5M1";
export var h5 = "ContactSupport-module--h5--DXA+M";
export var link_out_props = "ContactSupport-module--link_out_props--pNJ7n";
export var light_grey_bg = "ContactSupport-module--light_grey_bg--coL82";
export var card_container = "ContactSupport-module--card_container--X-Bul";
export var hyper_link = "ContactSupport-module--hyper_link--At0QM";
export var contact_support_section = "ContactSupport-module--contact_support_section--xhITN";
export var request_button = "ContactSupport-module--request_button--DQ0Mt";
export var chat_button = "ContactSupport-module--chat_button--oZwPY";
export var button = "ContactSupport-module--button--DS1fm";
export var card = "ContactSupport-module--card--rhO21";
export var contact_data_container = "ContactSupport-module--contact_data_container--415BZ";
export var container_header = "ContactSupport-module--container_header--EKfyC";
export var contact_support = "ContactSupport-module--contact_support--0Hzho";
export var dark_grey_bg = "ContactSupport-module--dark_grey_bg--mQU0u";
export var bg_container = "ContactSupport-module--bg_container--7Z37H";
export var hubspot_form = "ContactSupport-module--hubspot_form--q5kKQ";
export var hubspot_button = "ContactSupport-module--hubspot_button--e9V54";