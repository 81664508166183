import React from 'react'
import Page from '../components/Page'
import SEO from '../components/seo'
import ContactSupport from '../components/ContactSupport'

const ContactSupportPage = () => (
  <Page>
    <SEO
      title="Contact Opentrons Support | Automation Experts Ready to Help"
      description="Contact us and we will respond as quickly as possible. We work tirelessly to keep you up and running. The more people successfully automating their labwork, the better."
    />
    <ContactSupport />
  </Page>
)

export default ContactSupportPage
