export const HS_FORM_ID = '843247f8-43e8-4497-850e-e776bd660c8d'
export const HS_PORTAL_ID = '5383285'

export const OSLinkOutProps = {
  url: 'https://support.opentrons.com/en/articles/2687536-b-supported-operating-systems-for-ot-2-app/',
  gtm: {
    action: 'click',
    category: 'b-support',
    label: 'all-articles',
  },
}

export const docsLinkOutProps = {
  url: 'https://docs.opentrons.com/v1/index.html',
  gtm: {
    action: 'click',
    category: 'b-support',
    label: 'all-articles',
  },
}

export const llLinkOutProps = {
  url: 'https://support.opentrons.com/en/articles/3137426-what-labware-can-i-use-with-the-ot-2/',
  gtm: {
    action: 'click',
    category: 'b-support',
    label: 'all-articles',
  },
}

export const pipetteLinkOutProps = {
  url: 'https://support.opentrons.com/en/articles/2067321-a-attaching-pipettes/',
  gtm: {
    action: 'click',
    category: 'b-support',
    label: 'all-articles',
  },
}
export const networkLinkOutProps = {
  url: 'https://support.opentrons.com/en/articles/2687543-c-network-requirements-for-the-ot-2/',
  gtm: {
    action: 'click',
    category: 'b-support',
    label: 'all-articles',
  },
}
export const calibrateLinkOutProps = {
  url: 'https://support.opentrons.com/en/?q=calibration',
  gtm: {
    action: 'click',
    category: 'b-support',
    label: 'all-articles',
  },
}
