// @flow

import * as React from 'react'
import {Link} from 'gatsby'
import {ContactCard} from '../Contact'
import {Button, LinkOut} from '../../ui-components'

import CHAT_IMAGE from './assets/chat.svg'

import {
  OSLinkOutProps,
  docsLinkOutProps,
  llLinkOutProps,
  pipetteLinkOutProps,
  networkLinkOutProps,
  calibrateLinkOutProps,
} from './contact-support-data'

import * as styles from './ContactSupport.module.css'

const CONTACT_SUPPORT_DATA = [
  {
    question: <p>What Operating Systems are supported by the OT-2 app?</p>,
    solution: (
      <p>
        Windows 10, macOS 10.10 or later, Ubuntu 12.04 or later. Read it our
        guide{' '}
        <LinkOut className={styles.link_out_props} {...OSLinkOutProps}>
          here.
        </LinkOut>
      </p>
    ),
  },
  {
    question: <p>Where can I find more information about the Opentrons API?</p>,
    solution: (
      <p>
        More information can be found at{' '}
        <LinkOut className={styles.link_out_props} {...docsLinkOutProps}>
          docs.opentrons.com
        </LinkOut>
      </p>
    ),
  },
  {
    question: <p>What labware can I use with the OT-2?</p>,
    solution: (
      <p>
        Our{' '}
        <LinkOut className={styles.link_out_props} {...llLinkOutProps}>
          Labware Library{' '}
        </LinkOut>
        hosts labware supported by the OT-2.
      </p>
    ),
  },
  {
    question: <p>How do I attach pipettes to the OT-2?</p>,
    solution: (
      <p>
        Follow the 5 steps in our
        <LinkOut className={styles.link_out_props} {...pipetteLinkOutProps}>
          {' '}
          Pipette Attachment{' '}
        </LinkOut>
        support article.
      </p>
    ),
  },
  {
    question: <p>What are the network requirements for the OT-2?</p>,
    solution: (
      <p>
        Find your options in our
        <LinkOut className={styles.link_out_props} {...networkLinkOutProps}>
          {' '}
          Network Requirements{' '}
        </LinkOut>
        support article.
      </p>
    ),
    button: true,
  },
  {
    question: <p>Is calibration necessary for every protocol run?</p>,
    solution: (
      <p>
        Not necessarily, learn more in our
        <LinkOut className={styles.link_out_props} {...calibrateLinkOutProps}>
          {' '}
          Calibrate the OT-2{' '}
        </LinkOut>
        support article.
      </p>
    ),
  },
]

const chatButtonProps = {
  children: 'chat with us',
  className: styles.chat_button,
  outline: true,
}

const ButtonLinkOutProps = {
  children: 'view all articles',
  Component: 'a',
  className: styles.button,
  linkOutProps: {
    url: 'https://support.opentrons.com/',
    gtm: {
      action: 'click',
      category: 'b-support',
      label: 'all-articles',
    },
  },
}

export default function ContactSupport() {
  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.h2}>Get support help</h2>
        <h5 className={styles.h5}>
          Explore our in-depth support articles or contact our support team
        </h5>
      </div>
      <div className={styles.light_grey_bg}>
        <div className={styles.container_header}>
          <h3 className={styles.h3}>
            Find answers to frequently asked questions
          </h3>
          <h5 className={styles.h5}>
            Not already a customer?
            <Link className={styles.hyper_link} to="/contact/">
              Contact our sales team here.
            </Link>
          </h5>
        </div>

        <div className={styles.contact_data_container}>
          {CONTACT_SUPPORT_DATA.map((contact, i) => {
            return <ContactCard key={i} {...contact} />
          })}
          <Button {...ButtonLinkOutProps} />
        </div>
      </div>
      <div className={styles.contact_container}>
        <h3 className={styles.h3}>Still can’t find what you’re looking for?</h3>
        <h5 className={styles.h5}>
          Contact us using one of the methods below and we will respond as
          quickly as possible.
        </h5>
        <div className={styles.contact_support}>
          <div className={styles.contact_support_section}>
            <img src={CHAT_IMAGE} alt="" />
            <h4 className={styles.h4}>Opentrons Messenger</h4>
            <p className={styles.p}>
              We will respond within 2-8 business hours (9:00 AM – 6:00 PM EST,
              Monday through Friday.). Chat conversations are accessible here
              and at the email address you provide in the chat.
            </p>
            <a
              href="http://support.opentrons.com/"
              target="_blank"
              rel="noreferrer"
            >
              <Button {...chatButtonProps} />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.dark_grey_bg}>
        <div className={styles.bg_container}>
          <p>OPENTRONS HEADQUARTERS</p>
          <p>45-18 Ct Square W, Long Island City, NY 11101</p>
        </div>
      </div>
    </>
  )
}
